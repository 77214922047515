export const diagnosticTranslations = {
  en: {
    diagnosticDetails: "Diagnostic Details",
    reportedIssue: "Reported Issue",
    estimatedTime: "Estimated Time",
    estimatedCost: "Estimated Cost",
    requiredParts: "Required Parts",
    diagnosticNotes: "Diagnostic Notes",
    completeDiagnostic: "Complete Diagnostic",
    selectRepair: "Select a repair to begin diagnostic",
    noPendingRepairs: "No pending repairs",
    searchRepairs: "Search repairs...",
    partNumbersPlaceholder: "Enter required part numbers...",
    enterDiagnosticFindings: "Enter your diagnostic findings...",
    invalidTime: "Invalid time value",
    invalidCost: "Invalid cost value",
    notesRequired: "Notes are required",
  },
  uz: {
    diagnosticDetails: "Tashxis tafsilotlari",
    reportedIssue: "Xabar qilingan muammo",
    estimatedTime: "Taxminiy vaqt",
    estimatedCost: "Taxminiy narx",
    requiredParts: "Kerakli qismlar",
    diagnosticNotes: "Tashxis izohlari",
    completeDiagnostic: "Tashxisni yakunlash",
    selectRepair: "Tashxisni boshlash uchun ta'mirni tanlang",
    noPendingRepairs: "Kutilayotgan ta'mirlar yo'q",
    searchRepairs: "Ta'mirlarni qidirish...",
    partNumbersPlaceholder: "Kerakli qismlar raqamlarini kiriting...",
    enterDiagnosticFindings: "Tashxis natijalarini kiriting...",
    invalidTime: "Noto'g'ri vaqt qiymati",
    invalidCost: "Noto'g'ri narx qiymati",
    notesRequired: "Izohlar kiritilishi shart",
  }
};