export const customerHistoryTranslations = {
  en: {
    searchCustomers: "Search customers...",
    customerHistory: "Customer History",
    customerSince: "Customer since",
    searchResults: "Search Results",
    noCustomersFound: "No customers found",
    repairHistory: "Repair History",
    showingRepairs: "Showing {{shown}} of {{total}} repairs",
    noRepairsMatch: "No repairs match the selected filters",
    showFilters: "Show Filters",
    hideFilters: "Hide Filters",
    resetFilters: "Reset Filters",
    startDate: "Start Date",
    endDate: "End Date",
    paymentStatus: "Payment Status",
    repairStatus: "Repair Status",
    diagnosticStatus: "Diagnostic Status",
    allPayments: "All Payments",
    fullyPaid: "Fully Paid",
    partiallyPaid: "Partially Paid",
    unpaid: "Unpaid",
    withDiagnostic: "With Diagnostic",
    withoutDiagnostic: "Without Diagnostic",
    total: "Total",
    paid: "Paid",
    due: "Due"
  },
  uz: {
    searchCustomers: "Mijozlarni qidirish...",
    customerHistory: "Mijozlar tarixi",
    customerSince: "Mijoz sanadan beri",
    searchResults: "Qidiruv natijalari",
    noCustomersFound: "Mijozlar topilmadi",
    repairHistory: "Ta'mirlash tarixi",
    showingRepairs: "{{total}} tadan {{shown}} ta ta'mir ko'rsatilmoqda",
    noRepairsMatch: "Filtrlarga mos ta'mirlar topilmadi",
    showFilters: "Filtrlarni ko'rsatish",
    hideFilters: "Filtrlarni yashirish",
    resetFilters: "Filtrlarni tiklash",
    startDate: "Boshlanish sanasi",
    endDate: "Tugash sanasi",
    paymentStatus: "To'lov holati",
    repairStatus: "Ta'mirlash holati",
    diagnosticStatus: "Tashxis holati",
    allPayments: "Barcha to'lovlar",
    fullyPaid: "To'liq to'langan",
    partiallyPaid: "Qisman to'langan",
    unpaid: "To'lanmagan",
    withDiagnostic: "Tashxis bilan",
    withoutDiagnostic: "Tashxissiz",
    total: "Jami",
    paid: "To'langan",
    due: "Qoldiq"
  }
};