import React, { useEffect, useState } from 'react';
import { DollarSign, CreditCard, Receipt, AlertCircle, Search } from 'lucide-react';
import { useRepairOps } from '../lib/hooks/useRepairOps';
import { t } from '../lib/translations';
import type { Database } from '../lib/database.types';

type FinancialSummary = {
  servicedAmount: number;
  paidAmount: number;
  balanceDue: number;
};

type Repair = Database['public']['Tables']['repairs']['Row'] & {
  customer: Database['public']['Tables']['customers']['Row'];
  diagnostic: Database['public']['Tables']['diagnostics']['Row'][];
  payments?: Database['public']['Tables']['payments']['Row'][];
};

const Accounting = () => {
  const { getRepairs, getFinancialSummary, createPayment, loading, error } = useRepairOps();
  const [summary, setSummary] = useState<FinancialSummary | null>(null);
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [selectedRepair, setSelectedRepair] = useState<Repair | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [paymentAmount, setPaymentAmount] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('Credit Card');
  const [notes, setNotes] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const getLatestDiagnostic = (repair: Repair) => {
    if (!repair.diagnostic || repair.diagnostic.length === 0) return null;
    return repair.diagnostic.sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];
  };

  useEffect(() => {
    const loadData = async () => {
      const [summaryData, repairsData] = await Promise.all([
        getFinancialSummary(),
        getRepairs(10, 'completed')
      ]);

      if (summaryData) {
        setSummary(summaryData);
      }
      if (repairsData) {
        setRepairs(repairsData as Repair[]);
      }
    };

    loadData();
  }, []);

  const filteredRepairs = repairs.filter(repair => {
    const searchLower = searchQuery.toLowerCase();
    const matchesSearch = (
      repair.repair_id.toLowerCase().includes(searchLower) ||
      repair.customer.name.toLowerCase().includes(searchLower) ||
      repair.device_type.toLowerCase().includes(searchLower)
    );

    const diagnostic = getLatestDiagnostic(repair);
    const estimatedCost = diagnostic?.estimated_cost || 0;

    const totalPaid = (repair.payments || []).reduce((sum, payment) => sum + (payment.amount || 0), 0);

    return matchesSearch && totalPaid < estimatedCost;
  });

  const handleSubmit = async () => {
    if (!selectedRepair || !paymentAmount || parseFloat(paymentAmount) <= 0) {
      return;
    }

    setSubmitLoading(true);
    try {
      const payment = await createPayment({
        amount: parseFloat(paymentAmount),
        payment_method: paymentMethod,
        notes: notes || null,
        repair_id: selectedRepair.id,
      });

      if (payment) {
        const [newSummary, newRepairs] = await Promise.all([
          getFinancialSummary(),
          getRepairs(10, 'completed')
        ]);

        if (newSummary) {
          setSummary(newSummary);
        }
        if (newRepairs) {
          setRepairs(newRepairs as Repair[]);
        }

        setPaymentAmount('');
        setNotes('');
        setSelectedRepair(null);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('accounting')}</h2>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="grid grid-cols-12 gap-6">
        {/* Financial Summary */}
        <div className="col-span-12">
          <div className="grid grid-cols-3 gap-6 mb-8">
            <div className="bg-blue-50 p-6 rounded-lg border border-blue-100">
              <div className="text-blue-600 mb-2">{t('servicedAmount')}</div>
              <div className="text-2xl font-bold">
                ${summary?.servicedAmount.toFixed(2) || '0.00'}
              </div>
            </div>
            <div className="bg-green-50 p-6 rounded-lg border border-green-100">
              <div className="text-green-600 mb-2">{t('paidAmount')}</div>
              <div className="text-2xl font-bold">
                ${summary?.paidAmount.toFixed(2) || '0.00'}
              </div>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg border border-gray-100">
              <div className="text-gray-600 mb-2">{t('balanceDue')}</div>
              <div className="text-2xl font-bold">
                ${summary?.balanceDue.toFixed(2) || '0.00'}
              </div>
            </div>
          </div>
        </div>

        {/* Repair Selection */}
        <div className="col-span-4">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center gap-2 mb-6">
              <Search className="w-5 h-5 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t('searchRepairs')}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="space-y-4">
              {loading ? (
                <div className="animate-pulse space-y-4">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="h-24 bg-gray-100 rounded-lg"></div>
                  ))}
                </div>
              ) : filteredRepairs.length > 0 ? (
                filteredRepairs.map((repair) => {
                  const diagnostic = getLatestDiagnostic(repair);
                  const totalPaid = (repair.payments || []).reduce((sum, payment) => sum + (payment.amount || 0), 0);
                  const estimatedCost = diagnostic?.estimated_cost || 0;
                  const remainingBalance = estimatedCost - totalPaid;

                  return (
                    <button
                      key={repair.id}
                      onClick={() => {
                        setSelectedRepair(repair);
                        if (remainingBalance > 0) {
                          setPaymentAmount(remainingBalance.toString());
                        }
                      }}
                      className={`w-full p-4 border rounded-lg transition-colors text-left
                        ${
                          selectedRepair?.id === repair.id
                            ? 'border-blue-500 bg-blue-50'
                            : 'hover:border-blue-500'
                        }`}
                    >
                      <div className="font-mono text-sm text-gray-500">{repair.repair_id}</div>
                      <div className="font-medium">{repair.customer.name}</div>
                      <div className="text-sm text-gray-500 capitalize">
                        {repair.device_type}
                        {repair.device_model && ` - ${repair.device_model}`}
                      </div>
                      {diagnostic && (
                        <div className="mt-2 flex flex-col gap-1">
                          <div className="flex items-center gap-1 text-blue-600 text-sm">
                            <DollarSign className="w-4 h-4" />
                            <span>{t('total')}: ${estimatedCost.toFixed(2)}</span>
                          </div>
                          <div className="flex items-center gap-1 text-green-600 text-sm">
                            <DollarSign className="w-4 h-4" />
                            <span>{t('paid')}: ${totalPaid.toFixed(2)}</span>
                          </div>
                          <div className="flex items-center gap-1 text-orange-600 text-sm">
                            <DollarSign className="w-4 h-4" />
                            <span>{t('due')}: ${remainingBalance.toFixed(2)}</span>
                          </div>
                        </div>
                      )}
                    </button>
                  );
                })
              ) : (
                <div className="text-center text-gray-500 py-8">
                  {t('noRepairsWithPayments')}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Payment Form */}
        <div className="col-span-8">
          {selectedRepair ? (
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-6">{t('paymentDetails')}</h3>
              
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t('paymentAmount')}
                  </label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      value={paymentAmount}
                      onChange={(e) => setPaymentAmount(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={t('enterAmount')}
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    {t('paymentMethod')}
                  </label>
                  <div className="relative">
                    <CreditCard className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                    <select
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                    >
                      <option value="Credit Card">{t('creditCard')}</option>
                      <option value="Debit Card">{t('debitCard')}</option>
                      <option value="Cash">{t('cash')}</option>
                      <option value="Bank Transfer">{t('bankTransfer')}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('paymentNotes')}
                </label>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32"
                  placeholder={t('addPaymentNotes')}
                ></textarea>
              </div>

              <div className="flex justify-end gap-4 mt-6">
                <button
                  onClick={() => {
                    setPaymentAmount('');
                    setPaymentMethod('Credit Card');
                    setNotes('');
                    setSelectedRepair(null);
                  }}
                  className="flex items-center gap-2 px-6 py-3 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
                >
                  <Receipt className="w-5 h-5" />
                  {t('cancel')}
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={submitLoading || !paymentAmount || parseFloat(paymentAmount) <= 0}
                  className={`flex items-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors
                    ${(submitLoading || !paymentAmount || parseFloat(paymentAmount) <= 0) ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <DollarSign className="w-5 h-5" />
                  {submitLoading ? t('recording') : t('recordPayment')}
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white p-6 rounded-lg shadow-md h-full flex flex-col items-center justify-center text-gray-500">
              <CreditCard className="w-16 h-16 mb-4" />
              <p>{t('selectRepairPayment')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accounting;