import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { t } from '../../lib/translations';

export default function Hero() {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <h1 className="text-5xl font-bold text-gray-900 mb-8 leading-tight">
            Kompyuter va Printer ta'mirlash
            <br />
            <span className="text-gray-900">
              <span className="text-blue-600">Pro Masterlar</span>` uchun boshqaruv tizimi(CRM)
            </span>
          </h1>
          <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
            {t('heroDescription')}
          </p>
          <div className="flex justify-center gap-4">
            <Link
              to="/auth/signup"
              className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Bepul sinab ko'rish
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
            <a
              href="#features"
              className="inline-flex items-center px-8 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              {t('viewFeatures')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}