import React from 'react';
import { Link } from 'react-router-dom';
import { LogIn, ArrowLeft, AlertCircle } from 'lucide-react';
import { useAuth } from '../lib/auth';
import { t } from '../lib/translations';

export default function SignIn() {
  const { signInWithGoogle, loading, error } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/" className="flex items-center gap-2 text-gray-600 mb-8 mx-4">
          <ArrowLeft className="w-5 h-5" />
          {t('backToHome')}
        </Link>
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              Sign in to pMaster
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {t('signInDesc')}
            </p>
          </div>

          {error && (
            <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start gap-3">
              <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
              <div className="flex-1">
                <p className="text-red-700">{error}</p>
                <p className="text-sm text-red-600 mt-1">
                  {t('contactSupport')}
                </p>
              </div>
            </div>
          )}

          <div className="mt-8">
            <button
              onClick={() => signInWithGoogle()}
              disabled={loading}
              className="w-full flex justify-center items-center gap-3 px-4 py-3 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <img
                src="https://www.google.com/favicon.ico"
                alt="Google"
                className="w-5 h-5"
              />
              {loading ? t('signingIn') : t('continueWithGoogle')}
            </button>
          </div>

          <div className="mt-6">
            <p className="text-center text-sm text-gray-600">
              {t('dontHaveAccount')}{' '}
              <Link to="/auth/signup" className="text-blue-600 hover:text-blue-500">
                {t('signUpForFree')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}