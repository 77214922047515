export const landingTranslations = {
  en: {
    // Hero Section
    heroTitle: "Professional Computer and Printer Repair Management",
    heroSubtitle: "For Pro Masters",
    heroDescription: "The complete solution for PC repair shops. Track repairs, manage customers, and grow your business with our powerful yet easy-to-use platform.",
    viewFeatures: "View Features",
    powerfulFeatures: "Powerful Features for PC Repair Shops",
    startStreamlining: "Start Streamlining Your PC Repair Business Today",
    joinRepairShops: "Join repair shops already using MasterProUZ to manage their operations more efficiently. No credit card required to get started.",
    lessAdminWork: "Less Administrative Work",
    digitalTracking: "Digital Tracking",
    statusTracking: "24/7 Status Tracking",

    // Features
    smartLabelSystem: "Smart Label System",
    smartLabelDesc: "Generate QR code labels for each repair, enabling quick access to repair status and history. Streamline your workflow with digital tracking.",
    automaticQRGeneration: "Automatic QR code generation",
    instantStatusLookup: "Instant status lookup",
    comprehensiveTracking: "Comprehensive Tracking",
    comprehensiveTrackingDesc: "Keep detailed records of every repair, from intake to completion. Track diagnostics, parts, and customer communications in one place.",
    digitalIntakeForms: "Digital intake forms",
    diagnosticLogging: "Diagnostic logging",
    completionChecklists: "Completion checklists",
    financialManagement: "Financial Management",
    financialManagementDesc: "Track all repair costs and payments. Generate reports and maintain clear financial records for your business.",
    paymentTracking: "Payment tracking",
    costEstimation: "Cost estimation",
    smsNotifications: "SMS notifications",
  },
  uz: {
    // Hero Section
    heroTitle: "Professional Kompyuter va Printer ta'mirlash",
    heroSubtitle: "Pro Masterlar uchun boshqaruv",
    heroDescription: "Kompyuter ta'mirlash ustaxonalari uchun to'liq yechim. Bizning kuchli va qulay platformamiz yordamida ta'mirlashlarni kuzating, mijozlarni boshqaring va biznesingizni rivojlantiring.",
    viewFeatures: "Imkoniyatlarni ko'rish",
    powerfulFeatures: "Kompyuter ta'mirlash ustaxonalari uchun kuchli imkoniyatlar",
    startStreamlining: "Kompyuter ta'mirlash biznesingizni bugun optimallashtirishni boshlang",
    joinRepairShops: "O'z operatsiyalarini samarali boshqarish uchun MasterProUZ dan foydalanayotgan ta'mirlash ustaxonalariga qo'shiling. Boshlash uchun karta ma'lumotlari talab qilinmaydi.",
    lessAdminWork: "Kamroq ma'muriy ish",
    digitalTracking: "Raqamli kuzatuv",
    statusTracking: "24/7 holat kuzatuvi",

    // Features
    smartLabelSystem: "Aqlli yorliq tizimi",
    smartLabelDesc: "Har bir ta'mirlash uchun QR-kod yorliqlarini yarating, ta'mirlash holati va tarixiga tezkor kirishni ta'minlang. Ish jarayoningizni raqamli kuzatuv bilan optimallang.",
    automaticQRGeneration: "Avtomatik QR kod yaratish",
    instantStatusLookup: "Tezkor holat tekshiruvi",
    comprehensiveTracking: "Keng qamrovli kuzatuv",
    comprehensiveTrackingDesc: "Har bir ta'mirlashning batafsil yozuvlarini saqlang, qabuldan yakunlashgacha. Tashxislar, qismlar va mijozlar bilan muloqotni bir joyda kuzating.",
    digitalIntakeForms: "Raqamli qabul shakllari",
    diagnosticLogging: "Tashxis qaydlari",
    completionChecklists: "Yakunlash ro'yxatlari",
    financialManagement: "Moliyaviy boshqaruv",
    financialManagementDesc: "Barcha ta'mirlash xarajatlari va to'lovlarni kuzating. Hisobotlar yarating va biznesingiz uchun aniq moliyaviy yozuvlarni saqlang.",
    paymentTracking: "To'lov kuzatuvi",
    costEstimation: "Xarajat baholash",
    smsNotifications: "SMS xabarnomalar",
  }
};