export const commonTranslations: Record<'en' | 'uz', CommonTranslations> = {
  en: {
    // Auth
    signIn: "Sign in",
    signInTitle: "Sign in to MasterProUZ",
    signInDesc: "Access your repair shop dashboard",
    signInWithGoogle: "Sign in with Google",
    signInInstead: "Sign in instead",
    signUp: "Sign up",
    signOut: "Sign Out",
    continueWithGoogle: "Continue with Google",
    signingIn: "Signing in...",
    backToHome: "Back to Home",
    dontHaveAccount: "Don't have an account?",
    signUpForFree: "Sign up for free",
    alreadyHaveAccount: "Already have an account?",
    loading: "Loading...",
    contactSupport: "Please contact support if this issue persists",
    creatingAccount: "Creating account...",

    // Navigation
    customerIntake: "Customer Intake",
    customerHistory: "Customer History",
    labelGeneration: "Label Generation",
    diagnosticEntry: "Diagnostic Entry",
    accounting: "Accounting",
    completion: "Completion",
    dashboard: "Dashboard",
    dailyCashRegister: "Daily Cash Register",

    // Common
    customer: "Customer",
    device: "Device",
    issue: "Issue",
    status: "Status",
    createdAt: "Created At",
    and: "and",
    none: "None",
    all: "All",
    cancel: "Cancel",
    completing: "Completing...",
    today: "Today",
    yesterday: "Yesterday",
    thisWeek: "This Week",
    thisMonth: "This Month",
    total: "Total",
    income: "Income",
    expenses: "Expenses",
    balance: "Balance",
    date: "Date",
    amount: "Amount",
    type: "Type",
    description: "Description",
    addTransaction: "Add Transaction",
    export: "Export",
    print: "Print",

    // Status
    pending: "Pending",
    diagnosed: "Diagnosed",
    completed: "Completed",
    cancelled: "Cancelled",

    // Legal
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    agreeToTerms: "By signing up, you agree to our",
    legal: "Legal",
    allRightsReserved: "All rights reserved",
  },
  uz: {
    // Auth
    signIn: "Kirish",
    signInTitle: "MasterProUZ tizimiga kirish",
    signInDesc: "Ta'mirlash ustaxonangiz boshqaruv paneliga kiring",
    signInWithGoogle: "Google orqali kirish",
    signInInstead: "Kirish",
    signUp: "Ro'yxatdan o'tish",
    signOut: "Chiqish",
    continueWithGoogle: "Google orqali davom etish",
    signingIn: "Kirish...",
    backToHome: "Bosh sahifaga qaytish",
    dontHaveAccount: "Hisobingiz yo'qmi?",
    signUpForFree: "Bepul ro'yxatdan o'ting",
    alreadyHaveAccount: "Hisobingiz bormi?",
    loading: "Yuklanmoqda...",
    contactSupport: "Agar muammo davom etsa, qo'llab-quvvatlash xizmatiga murojaat qiling",
    creatingAccount: "Hisob yaratilmoqda...",

    // Navigation
    customerIntake: "Mijoz qabuli",
    customerHistory: "Mijozlar tarixi",
    labelGeneration: "Yorliq yaratish",
    diagnosticEntry: "Tashxis kiritish",
    accounting: "Buxgalteriya",
    completion: "Yakunlash",
    dashboard: "Boshqaruv paneli",
    dailyCashRegister: "Kunlik kassa",

    // Common
    customer: "Mijoz",
    device: "Qurilma",
    issue: "Muammo",
    status: "Holat",
    createdAt: "Yaratilgan sana",
    and: "va",
    none: "Yo'q",
    all: "Hammasi",
    cancel: "Bekor qilish",
    completing: "Yakunlanmoqda...",
    today: "Bugun",
    yesterday: "Kecha",
    thisWeek: "Shu hafta",
    thisMonth: "Shu oy",
    total: "Jami",
    income: "Kirim",
    expenses: "Chiqim",
    balance: "Balans",
    date: "Sana",
    amount: "Summa",
    type: "Turi",
    description: "Izoh",
    addTransaction: "Yangi yozuv",
    export: "Eksport",
    print: "Chop etish",

    // Status
    pending: "Kutilmoqda",
    diagnosed: "Tashxis qo'yilgan",
    completed: "Yakunlangan",
    cancelled: "Bekor qilingan",

    // Legal
    termsOfService: "Xizmat ko'rsatish shartlari",
    privacyPolicy: "Maxfiylik siyosati",
    agreeToTerms: "Ro'yxatdan o'tish orqali siz bizning",
    legal: "Huquqiy",
    allRightsReserved: "Barcha huquqlar himoyalangan",
  }
};