import { useState } from 'react';
import { supabase } from '../supabase';
import type { Database } from '../database.types';

type Customer = Database['public']['Tables']['customers']['Row'];
type Repair = Database['public']['Tables']['repairs']['Row'];
type Diagnostic = Database['public']['Tables']['diagnostics']['Row'];
type Payment = Database['public']['Tables']['payments']['Row'];
type CompletionRecord = Database['public']['Tables']['completion_records']['Row'];

export function useRepairOps() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getFinancialSummary = async () => {
    try {
      // Get total serviced amount (completed repairs with diagnostics)
      const { data: servicedData, error: servicedError } = await supabase
        .from('repairs')
        .select(`
          id,
          diagnostic:diagnostics(estimated_cost)
        `)
        .eq('status', 'completed');

      if (servicedError) throw servicedError;

      // Get total paid amount
      const { data: paymentsData, error: paymentsError } = await supabase
        .from('payments')
        .select('amount');

      if (paymentsError) throw paymentsError;

      // Calculate totals
      const servicedTotal = servicedData.reduce((sum, repair) => {
        const diagnostic = repair.diagnostic?.[0];
        return sum + (diagnostic?.estimated_cost || 0);
      }, 0);

      const paidTotal = paymentsData.reduce((sum, payment) => {
        return sum + (payment.amount || 0);
      }, 0);

      return {
        servicedAmount: servicedTotal,
        paidAmount: paidTotal,
        balanceDue: servicedTotal - paidTotal
      };
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    }
  };

  const searchCustomers = async (query: string) => {
    try {
      const { data, error: searchError } = await supabase
        .from('customers')
        .select('*')
        .or(`name.ilike.%${query}%,phone.ilike.%${query}%`)
        .limit(5);

      if (searchError) throw searchError;
      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    }
  };

  const createCustomerWithRepair = async (
    customerData: Omit<Customer, 'id' | 'created_at'>,
    repairData: Omit<Repair, 'id' | 'customer_id' | 'created_at' | 'updated_at'>
  ) => {
    setLoading(true);
    setError(null);

    try {
      // Insert customer
      const { data: customer, error: customerError } = await supabase
        .from('customers')
        .insert(customerData)
        .select()
        .single();

      if (customerError) throw customerError;
      if (!customer) throw new Error('Failed to create customer');

      // Insert repair
      const { data: repair, error: repairError } = await supabase
        .from('repairs')
        .insert({
          ...repairData,
          customer_id: customer.id,
        })
        .select()
        .single();

      if (repairError) throw repairError;
      return repair;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    } finally {
      setLoading(false);
    }
  };

  const createRepairForExistingCustomer = async (
    customerId: string,
    repairData: Omit<Repair, 'id' | 'customer_id' | 'created_at' | 'updated_at'>
  ) => {
    setLoading(true);
    setError(null);

    try {
      const { data: repair, error: repairError } = await supabase
        .from('repairs')
        .insert({
          ...repairData,
          customer_id: customerId,
        })
        .select()
        .single();

      if (repairError) throw repairError;
      return repair;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getRepairs = async (limit = 5, status = 'pending') => {
    try {
      // Fetch repairs with full details, ordered by creation date
      const { data, error } = await supabase
        .from('repairs')
        .select(`
          *,
          customer:customers(*),
          diagnostic:diagnostics(*),
          payments:payments(*)
        `)
        .eq('status', status)
        .order('created_at', { ascending: true })
        .limit(limit);

      if (error) throw error;
      return data;
    } catch (err) {
      console.error('Error in getRepairs:', err);
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    }
  };

  const createDiagnostic = async (diagnosticData: Omit<Diagnostic, 'id' | 'created_at' | 'updated_at'>) => {
    try {
      // Start a transaction by first creating the diagnostic
      const { data: diagnostic, error: diagnosticError } = await supabase
        .from('diagnostics')
        .insert(diagnosticData)
        .select()
        .single();

      if (diagnosticError) throw diagnosticError;

      // Then update the repair status to 'diagnosed'
      const { error: repairError } = await supabase
        .from('repairs')
        .update({ status: 'diagnosed' })
        .eq('id', diagnosticData.repair_id);

      if (repairError) throw repairError;

      return diagnostic;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    }
  };

  const createPayment = async (paymentData: Omit<Payment, 'id' | 'created_at'>) => {
    try {
      const { data, error } = await supabase
        .from('payments')
        .insert(paymentData)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    }
  };

  const cancelRepair = async (repairId: string) => {
    try {
      const { error } = await supabase
        .rpc('cancel_repair', { repair_id_param: repairId });

      if (error) throw error;
      return true;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return false;
    }
  };

  const completeRepair = async (
    repairId: string,
    completionData: Omit<CompletionRecord, 'id' | 'repair_id' | 'completed_at'>
  ) => {
    try {
      // Update repair status
      const { error: repairError } = await supabase
        .from('repairs')
        .update({ status: 'completed' })
        .eq('id', repairId);

      if (repairError) throw repairError;

      // Create completion record
      const { data, error: completionError } = await supabase
        .from('completion_records')
        .insert({
          ...completionData,
          repair_id: repairId,
        })
        .select()
        .single();

      if (completionError) throw completionError;
      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      return null;
    }
  };

  return {
    loading,
    error,
    searchCustomers,
    createCustomerWithRepair,
    createRepairForExistingCustomer,
    getRepairs,
    createDiagnostic,
    createPayment,
    completeRepair,
    cancelRepair,
    getFinancialSummary,
  };
}