import React from 'react';
import { Link } from 'react-router-dom';
import { Wrench, ArrowRight, LogIn } from 'lucide-react';
import { useAuth } from '../../lib/auth';
import { t } from '../../lib/translations';

export default function Header() {
  const { user } = useAuth();

  return (
    <header className="relative z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center gap-2">
            <Wrench className="w-6 h-6 text-blue-600" />
            <span className="text-xl font-bold text-gray-900">pMaster</span>
          </div>
          {user ? (
            <Link
              to="/dashboard"
              className="flex items-center gap-2 text-gray-700 hover:text-gray-900"
            >
              <span>{t('dashboard')}</span>
              <ArrowRight className="w-4 h-4" />
            </Link>
          ) : (
            <Link
              to="/auth/signin"
              className="flex items-center gap-2 text-gray-700 hover:text-gray-900"
            >
              <LogIn className="w-4 h-4" />
              <span>{t('signIn')}</span>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}