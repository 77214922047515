import React, { useState, useEffect } from 'react';
import { ClipboardList, Search, Clock, PenTool as Tool, AlertCircle, DollarSign, Info } from 'lucide-react';
import { useRepairOps } from '../lib/hooks/useRepairOps';
import { t } from '../lib/translations';
import type { Database } from '../lib/database.types';

type Repair = Database['public']['Tables']['repairs']['Row'] & {
  customer: Database['public']['Tables']['customers']['Row'];
};

type DiagnosticFormData = {
  estimated_time: number | null;
  estimated_cost: number | null;
  required_parts: string;
  notes: string;
};

const DiagnosticEntry = () => {
  const { getRepairs, createDiagnostic, loading, error } = useRepairOps();
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [selectedRepair, setSelectedRepair] = useState<Repair | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [formData, setFormData] = useState<DiagnosticFormData>({
    estimated_time: null,
    estimated_cost: null,
    required_parts: '',
    notes: '',
  });
  const [formErrors, setFormErrors] = useState<Partial<DiagnosticFormData>>({});
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchRepairs = async () => {
      const data = await getRepairs();
      if (data) {
        setRepairs(data as Repair[]);
      }
    };

    fetchRepairs();
  }, []);

  const filteredRepairs = repairs.filter(repair => {
    const searchLower = searchQuery.toLowerCase();
    return (
      repair.repair_id.toLowerCase().includes(searchLower) ||
      repair.customer.name.toLowerCase().includes(searchLower) ||
      repair.device_type.toLowerCase().includes(searchLower)
    );
  });

  const validateForm = () => {
    const errors: Partial<DiagnosticFormData> = {};
    let isValid = true;

    if (formData.estimated_time !== null && formData.estimated_time < 0) {
      errors.estimated_time = t('invalidTime');
      isValid = false;
    }

    if (formData.estimated_cost !== null && formData.estimated_cost < 0) {
      errors.estimated_cost = t('invalidCost');
      isValid = false;
    }

    if (!formData.notes?.trim()) {
      errors.notes = t('notesRequired');
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!selectedRepair || !validateForm()) return;

    setSubmitLoading(true);
    try {
      const diagnostic = await createDiagnostic({
        repair_id: selectedRepair.id,
        estimated_time: formData.estimated_time,
        estimated_cost: formData.estimated_cost,
        required_parts: formData.required_parts || null,
        notes: formData.notes,
      });

      if (diagnostic) {
        setRepairs(prev => prev.filter(r => r.id !== selectedRepair.id));
        setSelectedRepair(null);
        setFormData({
          estimated_time: null,
          estimated_cost: null,
          required_parts: '',
          notes: '',
        });
      }
    } catch (err) {
      console.error('Failed to submit diagnostic:', err);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-12 gap-6">
      {/* Repair List */}
      <div className="col-span-4 bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center gap-2 mb-6">
          <Search className="w-5 h-5 text-gray-400" />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('searchRepairs')}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start gap-3">
            <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
            <p className="text-red-700">{error}</p>
          </div>
        )}

        <div className="space-y-4">
          {loading ? (
            <div className="animate-pulse space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-24 bg-gray-100 rounded-lg"></div>
              ))}
            </div>
          ) : filteredRepairs.length > 0 ? (
            filteredRepairs.map((repair) => (
              <button
                key={repair.id}
                onClick={() => setSelectedRepair(repair)}
                className={`w-full p-4 border rounded-lg transition-colors text-left
                  ${
                    selectedRepair?.id === repair.id
                      ? 'border-blue-500 bg-blue-50'
                      : 'hover:border-blue-500'
                  }`}
              >
                <div className="font-mono text-sm text-gray-500">{repair.repair_id}</div>
                <div className="font-medium">{repair.customer.name}</div>
                <div className="text-sm text-gray-500 capitalize">
                  {repair.device_type}
                  {repair.device_model && ` - ${repair.device_model}`}
                </div>
              </button>
            ))
          ) : (
            <div className="text-center text-gray-500 py-8">
              {t('noPendingRepairs')}
            </div>
          )}
        </div>
      </div>

      {/* Diagnostic Form */}
      <div className="col-span-8 bg-white rounded-lg shadow-md p-6">
        {selectedRepair ? (
          <>
            <h3 className="text-xl font-semibold text-gray-800 mb-6">{t('diagnosticDetails')}</h3>
            
            {/* Issue Description Card */}
            <div className="mb-6 p-4 bg-blue-50 border border-blue-100 rounded-lg">
              <div className="flex items-start gap-3">
                <Info className="w-5 h-5 text-blue-500 mt-1" />
                <div>
                  <div className="font-medium text-blue-900 mb-1">{t('reportedIssue')}</div>
                  <p className="text-blue-800">{selectedRepair.issue_description}</p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6 mb-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('estimatedTime')}
                </label>
                <div className="flex items-center gap-2">
                  <Clock className="w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    value={formData.estimated_time || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      estimated_time: e.target.value ? Number(e.target.value) : null
                    }))}
                    className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      ${formErrors.estimated_time ? 'border-red-500' : 'border-gray-300'}`}
                  />
                </div>
                {formErrors.estimated_time && (
                  <p className="mt-1 text-sm text-red-600">{t('invalidTime')}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('estimatedCost')}
                </label>
                <div className="flex items-center gap-2">
                  <DollarSign className="w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    value={formData.estimated_cost || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      estimated_cost: e.target.value ? Number(e.target.value) : null
                    }))}
                    className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      ${formErrors.estimated_cost ? 'border-red-500' : 'border-gray-300'}`}
                  />
                </div>
                {formErrors.estimated_cost && (
                  <p className="mt-1 text-sm text-red-600">{t('invalidCost')}</p>
                )}
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('requiredParts')}
              </label>
              <div className="flex items-center gap-2">
                <Tool className="w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  value={formData.required_parts}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    required_parts: e.target.value
                  }))}
                  placeholder={t('partNumbersPlaceholder')}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('diagnosticNotes')}
              </label>
              <textarea
                value={formData.notes}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  notes: e.target.value
                }))}
                className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32
                  ${formErrors.notes ? 'border-red-500' : 'border-gray-300'}`}
                placeholder={t('enterDiagnosticFindings')}
              ></textarea>
              {formErrors.notes && (
                <p className="mt-1 text-sm text-red-600">{t('notesRequired')}</p>
              )}
            </div>

            <div className="flex justify-end gap-4">
              <button
                onClick={() => {
                  setSelectedRepair(null);
                  setFormData({
                    estimated_time: null,
                    estimated_cost: null,
                    required_parts: '',
                    notes: '',
                  });
                  setFormErrors({});
                }}
                className="px-6 py-3 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
              >
                {t('cancel')}
              </button>
              <button
                onClick={handleSubmit}
                disabled={submitLoading}
                className={`px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors
                  ${submitLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {submitLoading ? t('completing') : t('completeDiagnostic')}
              </button>
            </div>
          </>
        ) : (
          <div className="h-full flex flex-col items-center justify-center text-gray-500">
            <ClipboardList className="w-16 h-16 mb-4" />
            <p>{t('selectRepair')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiagnosticEntry;