import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Clock, PenTool as Tool, CheckCircle, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Database } from '../lib/database.types';

type Repair = Database['public']['Tables']['repairs']['Row'];
type Customer = Database['public']['Tables']['customers']['Row'];
type Diagnostic = Database['public']['Tables']['diagnostics']['Row'];
type Payment = Database['public']['Tables']['payments']['Row'];
type CompletionRecord = Database['public']['Tables']['completion_records']['Row'];

type FullRepairDetails = Repair & {
  customer: Customer;
  diagnostic: Diagnostic[];
  payments: Payment[];
  completion_records: CompletionRecord[];
};

const RepairStatus = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [repair, setRepair] = useState<FullRepairDetails | null>(null);

  useEffect(() => {
    const fetchRepairDetails = async () => {
      if (!id) {
        setError('No repair ID provided');
        setLoading(false);
        return;
      }

      try {
        const { data, error: fetchError } = await supabase
          .from('repairs')
          .select(`
            *,
            customer:customers(*),
            diagnostic:diagnostics(*),
            payments(*),
            completion_records(*)
          `)
          .eq('repair_id', id)
          .single();

        if (fetchError) throw fetchError;
        if (!data) throw new Error('Repair not found');

        setRepair(data as FullRepairDetails);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load repair details');
      } finally {
        setLoading(false);
      }
    };

    fetchRepairDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-pulse space-y-4 text-center">
          <div className="w-12 h-12 mx-auto">
            <Clock className="w-full h-full text-gray-300" />
          </div>
          <div className="text-gray-500">Loading repair details...</div>
        </div>
      </div>
    );
  }

  if (error || !repair) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          <div className="flex items-center gap-3 text-red-600 mb-4">
            <AlertCircle className="w-6 h-6 flex-shrink-0" />
            <p className="text-lg">{error || 'Repair not found'}</p>
          </div>
          <a
            href="/"
            className="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium"
          >
            &larr; Return to Customer Intake
          </a>
        </div>
      </div>
    );
  }

  const getLatestDiagnostic = () => {
    if (!repair.diagnostic || repair.diagnostic.length === 0) return null;
    return repair.diagnostic.sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'text-green-600 bg-green-50 border-green-200';
      case 'diagnosed':
        return 'text-blue-600 bg-blue-50 border-blue-200';
      case 'cancelled':
        return 'text-red-600 bg-red-50 border-red-200';
      default:
        return 'text-yellow-600 bg-yellow-50 border-yellow-200';
    }
  };

  const diagnostic = getLatestDiagnostic();
  const totalPaid = repair.payments?.reduce((sum, payment) => sum + payment.amount, 0) || 0;
  const estimatedCost = diagnostic?.estimated_cost || 0;
  const remainingBalance = Math.max(0, estimatedCost - totalPaid);

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          {/* Header */}
          <div className="p-6 border-b">
            <div className="flex justify-between items-start">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Repair Status</h1>
                <p className="text-sm text-gray-500 mt-1">ID: {repair.repair_id}</p>
              </div>
              <div className={`px-4 py-2 rounded-full border ${getStatusColor(repair.status)}`}>
                {repair.status.charAt(0).toUpperCase() + repair.status.slice(1)}
              </div>
            </div>
          </div>

          {/* Customer & Device Info */}
          <div className="p-6 border-b bg-gray-50">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Customer</h2>
                <p className="mt-2 text-gray-900">{repair.customer.name}</p>
                <p className="text-gray-600">{repair.customer.phone}</p>
              </div>
              <div>
                <h2 className="text-sm font-medium text-gray-500">Device</h2>
                <p className="mt-2 text-gray-900 capitalize">
                  {repair.device_type}
                  {repair.device_model && ` - ${repair.device_model}`}
                </p>
                <p className="text-gray-600">{repair.issue_description}</p>
              </div>
            </div>
          </div>

          {/* Financial Summary */}
          {diagnostic && (
            <div className="p-6 border-b">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Financial Summary</h2>
              <div className="grid grid-cols-3 gap-4">
                <div className="p-4 bg-blue-50 rounded-lg">
                  <p className="text-sm text-blue-600 mb-1">Estimated Cost</p>
                  <p className="text-xl font-semibold text-blue-700">
                    ${estimatedCost.toFixed(2)}
                  </p>
                </div>
                <div className="p-4 bg-green-50 rounded-lg">
                  <p className="text-sm text-green-600 mb-1">Amount Paid</p>
                  <p className="text-xl font-semibold text-green-700">
                    ${totalPaid.toFixed(2)}
                  </p>
                </div>
                {remainingBalance > 0 && (
                  <div className="p-4 bg-orange-50 rounded-lg">
                    <p className="text-sm text-orange-600 mb-1">Balance Due</p>
                    <p className="text-xl font-semibold text-orange-700">
                      ${remainingBalance.toFixed(2)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Status Timeline */}
          <div className="p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Progress</h2>
            <div className="space-y-6">
              <div className="flex items-start gap-4">
                <div className="flex-none">
                  <Clock className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <p className="font-medium">Received for Repair</p>
                  <p className="text-sm text-gray-500">
                    {new Date(repair.created_at).toLocaleDateString()} at{' '}
                    {new Date(repair.created_at).toLocaleTimeString()}
                  </p>
                </div>
              </div>

              {diagnostic && (
                <div className="flex items-start gap-4">
                  <div className="flex-none">
                    <Tool className="w-6 h-6 text-blue-500" />
                  </div>
                  <div>
                    <p className="font-medium">Diagnostic Completed</p>
                    <p className="text-sm text-gray-500 mt-1">{diagnostic.notes}</p>
                    {diagnostic.required_parts && (
                      <div className="mt-2">
                        <p className="text-sm font-medium text-gray-600">Required Parts:</p>
                        <p className="text-sm text-gray-500">{diagnostic.required_parts}</p>
                      </div>
                    )}
                    {diagnostic.estimated_time && (
                      <p className="text-sm text-gray-500 mt-1">
                        Estimated time: {diagnostic.estimated_time} hours
                      </p>
                    )}
                  </div>
                </div>
              )}

              {repair.completion_records && repair.completion_records.length > 0 && (
                <div className="flex items-start gap-4">
                  <div className="flex-none">
                    <CheckCircle className="w-6 h-6 text-green-500" />
                  </div>
                  <div>
                    <p className="font-medium">Repair Completed</p>
                    <p className="text-sm text-gray-500">
                      {new Date(repair.completion_records[0].completed_at).toLocaleDateString()} at{' '}
                      {new Date(repair.completion_records[0].completed_at).toLocaleTimeString()}
                    </p>
                    {repair.completion_records[0].notes && (
                      <p className="text-sm text-gray-500 mt-1">
                        {repair.completion_records[0].notes}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairStatus;