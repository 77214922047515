import React from 'react';
import { QrCode, FileText, MessageSquare, CheckCircle } from 'lucide-react';
import { t } from '../../lib/translations';

export default function Features() {
  return (
    <div className="py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-16">
          {t('powerfulFeatures')}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <FeatureCard
            icon={<QrCode className="w-6 h-6 text-blue-600" />}
            title={t('smartLabelSystem')}
            description={t('smartLabelDesc')}
            features={[
              t('automaticQRGeneration'),
              t('customLabelTemplates'),
              t('instantStatusLookup')
            ]}
            bgColor="blue"
          />
          <FeatureCard
            icon={<FileText className="w-6 h-6 text-green-600" />}
            title={t('comprehensiveTracking')}
            description={t('comprehensiveTrackingDesc')}
            features={[
              t('digitalIntakeForms'),
              t('diagnosticLogging'),
              t('completionChecklists')
            ]}
            bgColor="green"
          />
          <FeatureCard
            icon={<MessageSquare className="w-6 h-6 text-purple-600" />}
            title={t('financialManagement')}
            description={t('financialManagementDesc')}
            features={[
              t('paymentTracking'),
              t('costEstimation'),
              t('financialReporting')
            ]}
            bgColor="purple"
          />
        </div>
      </div>
    </div>
  );
}

type FeatureCardProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  features: string[];
  bgColor: 'blue' | 'green' | 'purple';
};

function FeatureCard({ icon, title, description, features, bgColor }: FeatureCardProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className={`w-12 h-12 bg-${bgColor}-100 rounded-lg flex items-center justify-center mb-4`}>
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-gray-900 mb-4">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
      <ul className="space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
            <CheckCircle className="w-4 h-4 text-green-500" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
}