import React, { useState, useEffect } from 'react';
import { CheckCircle, MessageSquare, AlertCircle, Search, DollarSign, Clock, XCircle } from 'lucide-react';
import { useRepairOps } from '../lib/hooks/useRepairOps';
import { t } from '../lib/translations';
import type { Database } from '../lib/database.types';

type Repair = Database['public']['Tables']['repairs']['Row'] & {
  customer: Database['public']['Tables']['customers']['Row'];
  diagnostic: Database['public']['Tables']['diagnostics']['Row'][];
};

type CompletionFormData = {
  checklist: {
    repairsCompleted: boolean;
    qualityAssurance: boolean;
    deviceCleaned: boolean;
    finalInspection: boolean;
  };
  notes: string;
  notificationSent: boolean;
};

const RepairCompletion = () => {
  const { getRepairs, completeRepair, cancelRepair, loading, error } = useRepairOps();
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [selectedRepair, setSelectedRepair] = useState<Repair | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [formData, setFormData] = useState<CompletionFormData>({
    checklist: {
      repairsCompleted: false,
      qualityAssurance: false,
      deviceCleaned: false,
      finalInspection: false,
    },
    notes: '',
    notificationSent: false,
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  useEffect(() => {
    const fetchRepairs = async () => {
      const data = await getRepairs(10, 'diagnosed');
      if (data) {
        setRepairs(data as Repair[]);
      }
    };

    fetchRepairs();
  }, []);

  const filteredRepairs = repairs.filter(repair => {
    const searchLower = searchQuery.toLowerCase();
    return (
      repair.repair_id.toLowerCase().includes(searchLower) ||
      repair.customer.name.toLowerCase().includes(searchLower) ||
      repair.device_type.toLowerCase().includes(searchLower)
    );
  });

  const isChecklistComplete = () => {
    return Object.values(formData.checklist).every(value => value);
  };

  const handleSubmit = async () => {
    if (!selectedRepair || !isChecklistComplete()) return;

    setSubmitLoading(true);
    try {
      const completion = await completeRepair(selectedRepair.id, {
        checklist_completed: true,
        notes: formData.notes || null,
        notification_sent: formData.notificationSent,
      });

      if (completion) {
        setRepairs(prev => prev.filter(r => r.id !== selectedRepair.id));
        setSelectedRepair(null);
        setFormData({
          checklist: {
            repairsCompleted: false,
            qualityAssurance: false,
            deviceCleaned: false,
            finalInspection: false,
          },
          notes: '',
          notificationSent: false,
        });
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCancel = async () => {
    if (!selectedRepair) return;

    setCancelLoading(true);
    try {
      const success = await cancelRepair(selectedRepair.id);
      if (success) {
        setRepairs(prev => prev.filter(r => r.id !== selectedRepair.id));
        setSelectedRepair(null);
        setFormData({
          checklist: {
            repairsCompleted: false,
            qualityAssurance: false,
            deviceCleaned: false,
            finalInspection: false,
          },
          notes: '',
          notificationSent: false,
        });
        setShowCancelConfirm(false);
      }
    } finally {
      setCancelLoading(false);
    }
  };

  const getLatestDiagnostic = (repair: Repair) => {
    if (!repair.diagnostic || repair.diagnostic.length === 0) return null;
    return repair.diagnostic[repair.diagnostic.length - 1];
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('completion')}</h2>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="grid grid-cols-12 gap-6">
        {/* Repair Selection */}
        <div className="col-span-4">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center gap-2 mb-6">
              <Search className="w-5 h-5 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t('searchRepairs')}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="space-y-4">
              {loading ? (
                <div className="animate-pulse space-y-4">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="h-24 bg-gray-100 rounded-lg"></div>
                  ))}
                </div>
              ) : filteredRepairs.length > 0 ? (
                filteredRepairs.map((repair) => {
                  const diagnostic = getLatestDiagnostic(repair);
                  return (
                    <button
                      key={repair.id}
                      onClick={() => setSelectedRepair(repair)}
                      className={`w-full p-4 border rounded-lg transition-colors text-left
                        ${
                          selectedRepair?.id === repair.id
                            ? 'border-blue-500 bg-blue-50'
                            : 'hover:border-blue-500'
                        }`}
                    >
                      <div className="font-mono text-sm text-gray-500">{repair.repair_id}</div>
                      <div className="font-medium">{repair.customer.name}</div>
                      <div className="text-sm text-gray-500 capitalize">
                        {repair.device_type}
                        {repair.device_model && ` - ${repair.device_model}`}
                      </div>
                      {diagnostic && (
                        <div className="mt-2 flex items-center gap-4 text-sm">
                          <div className="flex items-center gap-1 text-blue-600">
                            <DollarSign className="w-4 h-4" />
                            <span>${diagnostic.estimated_cost?.toFixed(2) || '0.00'}</span>
                          </div>
                          {diagnostic.estimated_time && (
                            <div className="flex items-center gap-1 text-gray-500">
                              <Clock className="w-4 h-4" />
                              <span>{diagnostic.estimated_time}h</span>
                            </div>
                          )}
                        </div>
                      )}
                    </button>
                  );
                })
              ) : (
                <div className="text-center text-gray-500 py-8">
                  {t('noRepairsForCompletion')}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Completion Form */}
        <div className="col-span-8">
          {selectedRepair ? (
            <div className="space-y-6">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-6">{t('completionChecklist')}</h3>
                <div className="space-y-4">
                  <label className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={formData.checklist.repairsCompleted}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        checklist: {
                          ...prev.checklist,
                          repairsCompleted: e.target.checked
                        }
                      }))}
                      className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
                    />
                    <span>{t('repairsCompleted')}</span>
                  </label>
                  <label className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={formData.checklist.qualityAssurance}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        checklist: {
                          ...prev.checklist,
                          qualityAssurance: e.target.checked
                        }
                      }))}
                      className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
                    />
                    <span>{t('qualityAssurance')}</span>
                  </label>
                  <label className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={formData.checklist.deviceCleaned}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        checklist: {
                          ...prev.checklist,
                          deviceCleaned: e.target.checked
                        }
                      }))}
                      className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
                    />
                    <span>{t('deviceCleaned')}</span>
                  </label>
                  <label className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={formData.checklist.finalInspection}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        checklist: {
                          ...prev.checklist,
                          finalInspection: e.target.checked
                        }
                      }))}
                      className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
                    />
                    <span>{t('finalInspection')}</span>
                  </label>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('completionNotes')}</h3>
                <textarea
                  value={formData.notes}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    notes: e.target.value
                  }))}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32"
                  placeholder={t('enterCompletionNotes')}
                />
              </div>

              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{t('customerNotification')}</h3>
                <div className="flex items-start gap-4">
                  <MessageSquare className="w-6 h-6 text-gray-400 mt-1" />
                  <div className="flex-1">
                    <div className="bg-gray-50 p-4 rounded-lg mb-4">
                      <p className="text-gray-600">
                        {t('repairCompletedMessage', {
                          amount: getLatestDiagnostic(selectedRepair)?.estimated_cost?.toFixed(2) || '0.00'
                        })}
                      </p>
                    </div>
                    <label className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        checked={formData.notificationSent}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          notificationSent: e.target.checked
                        }))}
                        className="w-5 h-5 text-blue-600 rounded focus:ring-blue-500"
                      />
                      <span className="text-sm text-gray-600">{t('markNotificationSent')}</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <button
                  onClick={() => setShowCancelConfirm(true)}
                  disabled={cancelLoading}
                  className="flex items-center gap-2 px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <XCircle className="w-5 h-5" />
                  {cancelLoading ? t('cancelling') : t('cancelRepair')}
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={submitLoading || !isChecklistComplete()}
                  className={`flex items-center gap-2 px-6 py-3 rounded-md transition-colors
                    ${
                      isChecklistComplete() && !submitLoading
                        ? 'bg-green-600 text-white hover:bg-green-700'
                        : 'bg-gray-200 text-gray-500 cursor-not-allowed'
                    }`}
                >
                  <CheckCircle className="w-5 h-5" />
                  {submitLoading ? t('completing') : t('completeRepair')}
                </button>
              </div>

              {/* Cancel Confirmation Modal */}
              {showCancelConfirm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                    <h4 className="text-xl font-semibold text-gray-800 mb-4">{t('confirmCancellation')}</h4>
                    <p className="text-gray-600 mb-6">
                      {t('cancelRepairWarning')}
                    </p>
                    <div className="flex justify-end gap-4">
                      <button
                        onClick={() => setShowCancelConfirm(false)}
                        className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                      >
                        {t('keepRepair')}
                      </button>
                      <button
                        onClick={handleCancel}
                        disabled={cancelLoading}
                        className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
                      >
                        {cancelLoading ? t('cancelling') : t('confirmCancel')}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="bg-white p-6 rounded-lg shadow-md h-full flex flex-col items-center justify-center text-gray-500">
              <CheckCircle className="w-16 h-16 mb-4" />
              <p>{t('selectRepairToComplete')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RepairCompletion;