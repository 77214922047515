import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { t } from '../../lib/translations';

export default function Pricing() {
  return (
    <div className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-16">
          {t('choosePlan')}
        </h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <PricingCard
            title={t('freePlan')}
            price="$0"
            period={t('forever')}
            features={[
              t('upTo50Repairs'),
              t('basicRepairTracking'),
              t('qrCodeLabels'),
              t('simplePaymentTracking')
            ]}
            buttonVariant="secondary"
          />
          <PricingCard
            title={t('premiumPlan')}
            price="$10"
            period={t('perMonth')}
            features={[
              t('unlimitedRepairs'),
              t('advancedFinancialManagement'),
              t('customLabelTemplates'),
              t('multiPhotoDocumentation'),
              t('prioritySupport'),
              t('dailyCashRegister')
            ]}
            buttonVariant="primary"
            popular
          />
        </div>
      </div>
    </div>
  );
}

type PricingCardProps = {
  title: string;
  price: string;
  period: string;
  features: string[];
  buttonVariant: 'primary' | 'secondary';
  popular?: boolean;
};

function PricingCard({ title, price, period, features, buttonVariant, popular }: PricingCardProps) {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md relative">
      {popular && (
        <div className="absolute top-0 right-0 -translate-y-1/2 px-3 py-1 bg-blue-600 text-white text-sm font-medium rounded-full">
          {t('popular')}
        </div>
      )}
      <h3 className="text-2xl font-bold text-gray-900">{title}</h3>
      <div className="mt-4 flex items-baseline">
        <span className="text-4xl font-bold text-gray-900">{price}</span>
        <span className="ml-2 text-gray-500">{period}</span>
      </div>
      <p className="mt-4 text-gray-600">{t('startManaging')}</p>
      <ul className="mt-6 space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start gap-3">
            <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
      <Link
        to="/auth/signup"
        className={`mt-8 block w-full text-center px-6 py-3 rounded-md ${
          buttonVariant === 'primary'
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
        }`}
      >
        Bepul sinab ko'rish
      </Link>
    </div>
  );
}