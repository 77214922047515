export const pricingTranslations = {
  en: {
    // Plans
    freePlan: "Free Plan",
    premiumPlan: "Premium Plan",
    forever: "forever",
    perMonth: "per month",
    choosePlan: "Choose Your Plan",
    startManaging: "Start managing your repair shop efficiently with our flexible plans",
    selectedPlan: "Selected",
    selectPlan: "Select Plan",
    popular: "Popular",
    premiumPaymentNote: "You'll be prompted for payment after sign up",
    noCardRequired: "No credit card required",
    upTo50Repairs: "Up to 50 repairs per month",
    basicRepairTracking: "Basic repair tracking",
    qrCodeLabels: "QR code labels",
    simplePaymentTracking: "Simple payment tracking",
    unlimitedRepairs: "Unlimited repairs",
    advancedFinancialManagement: "Advanced financial management",
    customLabelTemplates: "Custom label templates",
    multiPhotoDocumentation: "Multi-photo documentation",
    prioritySupport: "Priority support",
    dailyCashRegister: "Daily cash register",
    startFreeTrial: "Start Free Trial",
    getStartedFree: "Get Started Free",
    quickLinks: "Quick Links",
  },
  uz: {
    // Plans
    freePlan: "Bepul tarif",
    premiumPlan: "Premium tarif",
    forever: "doimiy",
    perMonth: "oyiga",
    choosePlan: "Tarifni tanlang",
    startManaging: "Ta'mirlash ustaxonangizni samarali boshqarishni boshlang",
    selectedPlan: "Tanlangan",
    selectPlan: "Tarifni tanlash",
    popular: "Ommabop",
    premiumPaymentNote: "Ro'yxatdan o'tgandan so'ng to'lov so'raladi",
    noCardRequired: "Karta ma'lumotlari talab qilinmaydi",
    upTo50Repairs: "Oyiga 50 tagacha ta'mirlash",
    basicRepairTracking: "Asosiy ta'mirlash kuzatuvi",
    qrCodeLabels: "QR kod yorliqlari",
    simplePaymentTracking: "Oddiy to'lov kuzatuvi",
    unlimitedRepairs: "Cheksiz ta'mirlashlar",
    advancedFinancialManagement: "Kengaytirilgan moliyaviy boshqaruv",
    customLabelTemplates: "Maxsus yorliq shablonlari",
    multiPhotoDocumentation: "Ko'p rasmli hujjatlashtirish",
    prioritySupport: "Ustuvor qo'llab-quvvatlash",
    dailyCashRegister: "Kunlik kassa",
    startFreeTrial: "Bepul sinovni boshlash",
    getStartedFree: "Bepul boshlash",
    quickLinks: "Tezkor havolalar",
  }
};