import { useState } from 'react';
import { supabase } from '../supabase';
import type { Database } from '../database.types';

type Transaction = Database['public']['Tables']['transactions']['Row'];
type TransactionInsert = Database['public']['Tables']['transactions']['Insert'];

type Period = 'today' | 'yesterday' | 'week' | 'month';

export function useTransactions() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getTransactions = async (period: Period = 'today') => {
    try {
      setLoading(true);
      setError(null);

      let startDate = new Date();
      let endDate = new Date();

      switch (period) {
        case 'yesterday':
          startDate.setDate(startDate.getDate() - 1);
          endDate = new Date(startDate);
          break;
        case 'week':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'month':
          startDate.setMonth(startDate.getMonth() - 1);
          break;
        default:
          // today - use default dates
          break;
      }

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const { data, error: fetchError } = await supabase
        .from('transactions')
        .select()
        .gte('created_at', startDate.toISOString())
        .lte('created_at', endDate.toISOString())
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;
      return data || [];
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch transactions');
      return [];
    } finally {
      setLoading(false);
    }
  };

  const addTransaction = async (transaction: Omit<TransactionInsert, 'id' | 'user_id' | 'created_at'>) => {
    try {
      setLoading(true);
      setError(null);

      const { data, error: insertError } = await supabase
        .from('transactions')
        .insert(transaction)
        .select()
        .single();

      if (insertError) throw insertError;
      return data;
    } catch (err) {
      console.error('Error adding transaction:', err);
      setError(err instanceof Error ? err.message : 'Failed to add transaction');
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getSummary = async (period: Period = 'today') => {
    try {
      const transactions = await getTransactions(period);

      const income = transactions
        .filter(t => t.type === 'income')
        .reduce((sum, t) => sum + Number(t.amount), 0);

      const expenses = transactions
        .filter(t => t.type === 'expense')
        .reduce((sum, t) => sum + Number(t.amount), 0);

      return {
        income,
        expenses,
        balance: income - expenses
      };
    } catch (err) {
      console.error('Error calculating summary:', err);
      return {
        income: 0,
        expenses: 0,
        balance: 0
      };
    }
  };

  return {
    loading,
    error,
    getTransactions,
    addTransaction,
    getSummary
  };
}