import React, { useState, useEffect } from 'react';
import { Search, User, Phone, Wrench, DollarSign, Clock, AlertCircle, Filter } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { t } from '../lib/translations';
import type { Database } from '../lib/database.types';

type Customer = Database['public']['Tables']['customers']['Row'];
type Repair = Database['public']['Tables']['repairs']['Row'] & {
  customer: Database['public']['Tables']['customers']['Row'];
  diagnostic: Database['public']['Tables']['diagnostics']['Row'][];
};

type CustomerWithRepairs = Customer & {
  repairs: Repair[];
};

type FilterState = {
  dateRange: {
    start: string;
    end: string;
  };
  paymentStatus: 'all' | 'paid' | 'unpaid' | 'partial';
  repairStatus: string[];
  hasdiagnostic: boolean | null;
};

const CustomerHistory = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerWithRepairs | null>(null);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<FilterState>({
    dateRange: {
      start: '',
      end: '',
    },
    paymentStatus: 'all',
    repairStatus: [],
    hasdiagnostic: null,
  });

  const handleSearch = async (query: string) => {
    setSearchQuery(query);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (query.length < 2) {
      setSearchResults([]);
      return;
    }

    const timeout = setTimeout(async () => {
      try {
        const { data, error } = await supabase
          .from('customers')
          .select('*')
          .or(`name.ilike.%${query}%,phone.ilike.%${query}%`)
          .limit(5);

        if (error) throw error;
        setSearchResults(data || []);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to search customers');
      }
    }, 300);

    setSearchTimeout(timeout);
  };

  const selectCustomer = async (customer: Customer) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('repairs')
        .select(`
          *,
          diagnostic:diagnostics(*)
        `)
        .eq('customer_id', customer.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setSelectedCustomer({
        ...customer,
        repairs: data || []
      });
      setSearchResults([]);
      setSearchQuery('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load customer repairs');
    } finally {
      setLoading(false);
    }
  };

  const getLatestDiagnostic = (repair: Repair) => {
    if (!repair.diagnostic || repair.diagnostic.length === 0) return null;
    return repair.diagnostic.sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];
  };

  const getFilteredRepairs = () => {
    if (!selectedCustomer) return [];

    return selectedCustomer.repairs.filter(repair => {
      // Date range filter
      if (filters.dateRange.start || filters.dateRange.end) {
        const repairDate = new Date(repair.created_at);
        if (filters.dateRange.start && repairDate < new Date(filters.dateRange.start)) {
          return false;
        }
        if (filters.dateRange.end && repairDate > new Date(filters.dateRange.end)) {
          return false;
        }
      }

      // Repair status filter
      if (filters.repairStatus.length > 0 && !filters.repairStatus.includes(repair.status)) {
        return false;
      }

      // Diagnostic filter
      if (filters.hasdiagnostic !== null) {
        const hasDiagnostic = repair.diagnostic && repair.diagnostic.length > 0;
        if (filters.hasdiagnostic !== hasDiagnostic) {
          return false;
        }
      }

      return true;
    });
  };

  const resetFilters = () => {
    setFilters({
      dateRange: {
        start: '',
        end: '',
      },
      paymentStatus: 'all',
      repairStatus: [],
      hasdiagnostic: null,
    });
  };

  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);

  const filteredRepairs = getFilteredRepairs();

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('customerHistory')}</h2>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      {/* Customer Search */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <div className="relative">
          <div className="flex items-center gap-2 mb-2">
            <Search className="w-5 h-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t('searchCustomers')}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {searchResults.length > 0 && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
              {searchResults.map((customer) => (
                <button
                  key={customer.id}
                  onClick={() => selectCustomer(customer)}
                  className="w-full px-4 py-3 text-left hover:bg-gray-50 flex items-center gap-3"
                >
                  <User className="w-5 h-5 text-gray-400" />
                  <div>
                    <div className="font-medium">{customer.name}</div>
                    <div className="text-sm text-gray-500">{customer.phone}</div>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Customer Details and Repair History */}
      {selectedCustomer && (
        <div className="space-y-6">
          {/* Customer Info */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-xl font-semibold text-gray-800">{selectedCustomer.name}</h3>
                <div className="flex items-center gap-2 text-gray-600">
                  <Phone className="w-4 h-4" />
                  <span>{selectedCustomer.phone}</span>
                </div>
              </div>
              <div className="text-sm text-gray-500">
                {t('customerSince')}: {new Date(selectedCustomer.created_at).toLocaleDateString()}
              </div>
            </div>
          </div>

          {/* Filters */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-4">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
              >
                <Filter className="w-5 h-5" />
                <span>{showFilters ? t('hideFilters') : t('showFilters')}</span>
              </button>
              {showFilters && (
                <button
                  onClick={resetFilters}
                  className="text-sm text-gray-500 hover:text-gray-700"
                >
                  {t('resetFilters')}
                </button>
              )}
            </div>

            {showFilters && (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t('startDate')}
                    </label>
                    <input
                      type="date"
                      value={filters.dateRange.start}
                      onChange={(e) => setFilters(prev => ({
                        ...prev,
                        dateRange: { ...prev.dateRange, start: e.target.value }
                      }))}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t('endDate')}
                    </label>
                    <input
                      type="date"
                      value={filters.dateRange.end}
                      onChange={(e) => setFilters(prev => ({
                        ...prev,
                        dateRange: { ...prev.dateRange, end: e.target.value }
                      }))}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('repairStatus')}
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {['pending', 'diagnosed', 'completed', 'cancelled'].map((status) => (
                      <label key={status} className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={filters.repairStatus.includes(status)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFilters(prev => ({
                                ...prev,
                                repairStatus: [...prev.repairStatus, status]
                              }));
                            } else {
                              setFilters(prev => ({
                                ...prev,
                                repairStatus: prev.repairStatus.filter(s => s !== status)
                              }));
                            }
                          }}
                          className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                        />
                        <span className="text-sm capitalize">{t(status)}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('diagnosticStatus')}
                  </label>
                  <div className="flex gap-4">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        checked={filters.hasdiagnostic === null}
                        onChange={() => setFilters(prev => ({ ...prev, hasdiagnostic: null }))}
                        className="w-4 h-4 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-sm">{t('all')}</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        checked={filters.hasdiagnostic === true}
                        onChange={() => setFilters(prev => ({ ...prev, hasdiagnostic: true }))}
                        className="w-4 h-4 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-sm">{t('withDiagnostic')}</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        checked={filters.hasdiagnostic === false}
                        onChange={() => setFilters(prev => ({ ...prev, hasdiagnostic: false }))}
                        className="w-4 h-4 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-sm">{t('withoutDiagnostic')}</span>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Repair History */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-semibold text-gray-800">{t('repairHistory')}</h3>
              <div className="text-sm text-gray-500">
                {t('showingRepairs').replace('{{shown}}', filteredRepairs.length.toString()).replace('{{total}}', selectedCustomer.repairs.length.toString())}
              </div>
            </div>
            
            {loading ? (
              <div className="animate-pulse space-y-4">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="h-32 bg-gray-100 rounded-lg"></div>
                ))}
              </div>
            ) : filteredRepairs.length > 0 ? (
              <div className="space-y-6">
                {filteredRepairs.map((repair) => {
                  const diagnostic = getLatestDiagnostic(repair);

                  return (
                    <div
                      key={repair.id}
                      className="border rounded-lg p-4 space-y-4"
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <div className="flex items-center gap-2">
                            <Wrench className="w-5 h-5 text-gray-400" />
                            <span className="font-medium capitalize">
                              {repair.device_type}
                              {repair.device_model && ` - ${repair.device_model}`}
                            </span>
                          </div>
                          <div className="mt-1 text-sm text-gray-500">
                            ID: {repair.repair_id}
                          </div>
                        </div>
                        <div className="text-sm">
                          <span className={`px-3 py-1 rounded-full ${
                            repair.status === 'completed'
                              ? 'bg-green-100 text-green-800'
                              : repair.status === 'diagnosed'
                              ? 'bg-blue-100 text-blue-800'
                              : repair.status === 'cancelled'
                              ? 'bg-red-100 text-red-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}>
                            {t(repair.status)}
                          </span>
                        </div>
                      </div>

                      <div className="text-sm text-gray-600">
                        <strong>{t('issue')}:</strong> {repair.issue_description}
                      </div>

                      {diagnostic && (
                        <div className="grid grid-cols-2 gap-4 text-sm">
                          <div>
                            <div className="text-gray-500 mb-1">{t('diagnosticNotes')}</div>
                            <div>{diagnostic.notes}</div>
                          </div>
                          <div>
                            <div className="text-gray-500 mb-1">{t('requiredParts')}</div>
                            <div>{diagnostic.required_parts || t('none')}</div>
                          </div>
                        </div>
                      )}

                      <div className="flex items-center justify-between pt-4 border-t">
                        <div className="flex items-center gap-2 text-gray-500">
                          <Clock className="w-4 h-4" />
                          <span>{new Date(repair.created_at).toLocaleDateString()}</span>
                        </div>
                        
                        {diagnostic && diagnostic.estimated_cost && (
                          <div className="flex items-center gap-1 text-blue-600">
                            <DollarSign className="w-4 h-4" />
                            <span>{t('estimatedCost')}: ${diagnostic.estimated_cost.toFixed(2)}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center text-gray-500 py-8">
                {t('noRepairsMatch')}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerHistory;