import { z } from 'zod';

export const generateRepairId = () => {
  return 'REP' + Math.random().toString(36).substr(2, 9).toUpperCase();
};

export const customerSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  phone: z.string().min(1, 'Phone number is required'),
});

export const repairSchema = z.object({
  repair_id: z.string(),
  device_type: z.string().min(1, 'Device type is required'),
  device_model: z.string().min(1, 'Device model is required'),
  issue_description: z.string().min(1, 'Issue description is required'),
});

export const diagnosticSchema = z.object({
  repair_id: z.string(),
  estimated_time: z.number().min(0).nullable(),
  required_parts: z.string().nullable(),
  notes: z.string().nullable(),
});

export const paymentSchema = z.object({
  repair_id: z.string(),
  amount: z.number().min(0),
  payment_method: z.string().min(1, 'Payment method is required'),
  notes: z.string().nullable(),
});

export const completionSchema = z.object({
  checklist_completed: z.boolean(),
  notes: z.string().nullable(),
  notification_sent: z.boolean(),
});