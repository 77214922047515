import React from 'react';
import { Link } from 'react-router-dom';
import { Wrench } from 'lucide-react';
import { t } from '../../lib/translations';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-400 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-2">
            <div className="flex items-center gap-2 mb-4">
              <Wrench className="w-6 h-6 text-blue-500" />
              <span className="text-xl font-bold text-white">pMaster</span>
            </div>
            <p className="text-gray-400 mb-4">
              {t('heroDescription')}
            </p>
          </div>
          <div>
            <h3 className="text-white font-semibold mb-4">{t('quickLinks')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/auth/signup" className="hover:text-white transition-colors">
                  {t('getStartedFree')}
                </Link>
              </li>
              <li>
                <Link to="/auth/signin" className="hover:text-white transition-colors">
                  {t('signIn')}
                </Link>
              </li>
              <li>
                <Link to="/mlogin" className="hover:text-white transition-colors">
                  Email Sign In
                </Link>
              </li>
              <li>
                <Link to="/mregister" className="hover:text-white transition-colors">
                  Email Register
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-white font-semibold mb-4">{t('legal')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/terms" className="hover:text-white transition-colors">
                  {t('termsOfService')}
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="hover:text-white transition-colors">
                  {t('privacyPolicy')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-sm">
              © {new Date().getFullYear()} pMaster. {t('allRightsReserved')}
            </p>
            <a 
              href="https://thedevs.uz" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-sm text-gray-400 hover:text-white transition-colors"
            >
              Powered by TheDevs.uz
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}