import { Language, TranslationKey } from './translations/types';
import { commonTranslations } from './translations/common';
import { diagnosticTranslations } from './translations/diagnostic';
import { landingTranslations } from './translations/landing';
import { pricingTranslations } from './translations/pricing';
import { customerHistoryTranslations } from './translations/customerHistory';

// Merge all translations
const translations = {
  en: {
    ...commonTranslations.en,
    ...diagnosticTranslations.en,
    ...landingTranslations.en,
    ...pricingTranslations.en,
    ...customerHistoryTranslations.en,
    searchCustomers: "Search customers...",
  },
  uz: {
    ...commonTranslations.uz,
    ...diagnosticTranslations.uz,
    ...landingTranslations.uz,
    ...pricingTranslations.uz,
    ...customerHistoryTranslations.uz,
    searchCustomers: "Mijozlarni qidirish...",
  }
};

let currentLanguage: Language = 'uz';

export const setLanguage = (lang: Language) => {
  currentLanguage = lang;
};

export const t = (key: TranslationKey): string => {
  return translations[currentLanguage][key] || translations.en[key] || key;
};

export type { Language, TranslationKey };
export { translations };