import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './lib/auth';
import { LanguageProvider } from './lib/LanguageContext';
import Sidebar from './components/Sidebar';
import Landing from './screens/Landing';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import ManualLogin from './screens/ManualLogin';
import ManualRegister from './screens/ManualRegister';
import BusinessSetup from './screens/BusinessSetup';
import CustomerIntake from './screens/CustomerIntake';
import CustomerHistory from './screens/CustomerHistory';
import LabelGeneration from './screens/LabelGeneration';
import DiagnosticEntry from './screens/DiagnosticEntry';
import Accounting from './screens/Accounting';
import RepairCompletion from './screens/RepairCompletion';
import DailyCash from './screens/DailyCash';
import RepairStatus from './screens/RepairStatus';
import AuthCallback from './screens/AuthCallback';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsOfService from './screens/TermsOfService';
import AuthButton from './components/AuthButton';
import { useAuth } from './lib/auth';
import { supabase } from './lib/supabase';

function RequireBusinessProfile({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(true);
  const [hasProfile, setHasProfile] = React.useState(false);

  React.useEffect(() => {
    async function checkBusinessProfile() {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase
          .from('business_profiles')
          .select('id')
          .eq('user_id', user.id);

        if (error) {
          console.warn('Error checking business profile:', error);
          setHasProfile(false);
        } else {
          setHasProfile(data && data.length > 0);
        }
      } catch (err) {
        console.warn('Error checking business profile:', err);
        setHasProfile(false);
      } finally {
        setLoading(false);
      }
    }

    checkBusinessProfile();
  }, [user]);

  if (loading) {
    return null;
  }

  if (!hasProfile) {
    return <Navigate to="/setup" replace />;
  }

  return <>{children}</>;
}

function AppLayout() {
  const { user } = useAuth();

  if (!user) {
    return <Landing />;
  }

  return (
    <RequireBusinessProfile>
      <div className="flex min-h-screen bg-gray-100">
        <Sidebar />
        <main className="flex-1">
          <div className="p-4 flex justify-end">
            <AuthButton />
          </div>
          <div className="p-8">
            <Routes>
              <Route path="/" element={<CustomerIntake />} />
              <Route path="/history" element={<CustomerHistory />} />
              <Route path="/label" element={<LabelGeneration />} />
              <Route path="/label/:id" element={<LabelGeneration />} />
              <Route path="/diagnostic" element={<DiagnosticEntry />} />
              <Route path="/accounting" element={<Accounting />} />
              <Route path="/completion" element={<RepairCompletion />} />
              <Route path="/daily-cash" element={<DailyCash />} />
            </Routes>
          </div>
        </main>
      </div>
    </RequireBusinessProfile>
  );
}

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <Router>
          <Routes>
            <Route path="/status/:id" element={<RepairStatus />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/auth/signin" element={<SignIn />} />
            <Route path="/auth/signup" element={<SignUp />} />
            <Route path="/mlogin" element={<ManualLogin />} />
            <Route path="/mregister" element={<ManualRegister />} />
            <Route path="/setup" element={<BusinessSetup />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/*" element={<AppLayout />} />
          </Routes>
        </Router>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;