import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Printer as PrinterIcon, 
  RotateCcw, 
  AlertCircle, 
  Search, 
  User, 
  Clock, 
  Download,
  QrCode,
  Barcode,
  Phone,
  Calendar,
  ArrowLeft
} from 'lucide-react';
import { supabase } from '../lib/supabase';
import { t } from '../lib/translations';
import type { Database } from '../lib/database.types';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { jsPDF } from 'jspdf';

type Repair = Database['public']['Tables']['repairs']['Row'];
type Customer = Database['public']['Tables']['customers']['Row'];

type RepairWithCustomer = Repair & {
  customer: Customer;
};

const LabelPreview = ({ repair }: { repair: RepairWithCustomer }) => {
  return (
    <div className="border rounded-lg p-6 bg-white shadow-inner">
      <div className="max-w-[90mm] mx-auto aspect-[9/5] bg-white border shadow-lg p-4">
        {/* Company Name */}
        <div className="text-center mb-2">
          <h3 className="text-lg font-bold">MasterProUZ</h3>
          <div className="text-sm text-gray-600">ID: {repair.repair_id}</div>
        </div>

        {/* QR and Barcode */}
        <div className="flex justify-between items-center mb-4">
          <div className="w-20 h-20 bg-gray-100 rounded flex items-center justify-center">
            <QrCode className="w-12 h-12 text-gray-400" />
          </div>
          <div className="flex-1 ml-4">
            <div className="h-12 bg-gray-100 rounded flex items-center justify-center">
              <Barcode className="w-32 h-6 text-gray-400" />
            </div>
          </div>
        </div>

        {/* Customer Info */}
        <div className="text-xs space-y-1">
          <div className="flex items-center gap-1">
            <User className="w-3 h-3" />
            <span>{repair.customer.name}</span>
          </div>
          <div className="flex items-center gap-1">
            <Phone className="w-3 h-3" />
            <span>{repair.customer.phone}</span>
          </div>
          <div className="flex items-center gap-1">
            <PrinterIcon className="w-3 h-3" />
            <span>{repair.device_type}{repair.device_model ? ` - ${repair.device_model}` : ''}</span>
          </div>
          <div className="flex items-center gap-1 text-gray-500">
            <Calendar className="w-3 h-3" />
            <span>{new Date(repair.created_at).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const LabelGeneration = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [repair, setRepair] = useState<RepairWithCustomer | null>(null);
  const [latestRepairs, setLatestRepairs] = useState<RepairWithCustomer[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState<'customer' | 'repair'>('customer');
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    const fetchLatestRepairs = async () => {
      try {
        const { data, error: fetchError } = await supabase
          .from('repairs')
          .select(`
            *,
            customer:customers(*)
          `)
          .order('created_at', { ascending: false })
          .limit(5);

        if (fetchError) throw fetchError;
        setLatestRepairs(data as RepairWithCustomer[] || []);
      } catch (err) {
        console.error('Error fetching latest repairs:', err);
      }
    };

    if (!id) {
      fetchLatestRepairs();
    }
  }, [id]);

  useEffect(() => {
    const fetchRepair = async () => {
      if (!id) {
        setLoading(false);
        return;
      }

      try {
        const { data, error: fetchError } = await supabase
          .from('repairs')
          .select(`
            *,
            customer:customers(*)
          `)
          .eq('repair_id', id)
          .single();

        if (fetchError) throw fetchError;
        if (!data) throw new Error('Repair not found');

        setRepair(data as RepairWithCustomer);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load repair details');
      } finally {
        setLoading(false);
      }
    };

    fetchRepair();
  }, [id]);

  const handleSearch = async (query: string) => {
    setSearchQuery(query);

    if (query.length < 2) {
      setLatestRepairs([]);
      return;
    }

    try {
      let queryBuilder = supabase.from('repairs');

      if (searchType === 'customer') {
        queryBuilder = queryBuilder
          .select(`
            *,
            customer:customers!inner(*)
          `)
          .ilike('customer.name', `%${query}%`);
      } else {
        queryBuilder = queryBuilder
          .select(`
            *,
            customer:customers(*)
          `)
          .ilike('repair_id', `%${query}%`);
      }

      const { data, error: searchError } = await queryBuilder
        .order('created_at', { ascending: false })
        .limit(5);

      if (searchError) throw searchError;
      setLatestRepairs(data as RepairWithCustomer[] || []);
    } catch (err) {
      console.error('Error searching repairs:', err);
    }
  };

  const generatePDF = async () => {
    if (!repair) return;

    setGenerating(true);
    try {
      // Create PDF with custom size (90mm x 50mm)
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [50, 90]
      });

      // Set white background
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, 0, 90, 50, 'F');

      // Add company name
      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');
      pdf.text('MasterProUZ', 45, 7, { align: 'center' });

      // Add repair ID
      pdf.setFontSize(10);
      pdf.setFont('helvetica', 'normal');
      pdf.text(`ID: ${repair.repair_id}`, 45, 12, { align: 'center' });

      // Generate QR code
      const statusUrl = `${window.location.origin}/status/${repair.repair_id}`;
      const qrDataUrl = await QRCode.toDataURL(statusUrl, {
        width: 200,
        margin: 0,
      });

      // Add QR code (left side)
      pdf.addImage(qrDataUrl, 'PNG', 5, 15, 20, 20);

      // Generate barcode
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, repair.repair_id, {
        format: "CODE128",
        width: 1.5,
        height: 30,
        displayValue: false,
        background: "#FFFFFF",
        lineColor: "#000000",
        margin: 0
      });

      // Add barcode (right side)
      pdf.addImage(canvas.toDataURL(), 'PNG', 30, 17, 55, 15);

      // Add customer info
      pdf.setFontSize(8);
      pdf.text(`${t('customer')}: ${repair.customer.name}`, 5, 40);
      pdf.text(`${t('device')}: ${repair.device_type}${repair.device_model ? ` - ${repair.device_model}` : ''}`, 5, 44);

      // Add date
      pdf.setFontSize(6);
      pdf.text(new Date(repair.created_at).toLocaleDateString(), 85, 47, { align: 'right' });

      // Save the PDF
      pdf.save(`repair-label-${repair.repair_id}.pdf`);
    } finally {
      setGenerating(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('labelGeneration')}</h2>
        <div className="bg-white p-8 rounded-lg shadow-md">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-200 rounded w-1/2 mx-auto"></div>
            <div className="h-24 bg-gray-200 rounded mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }

  if (repair) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-4">
            <button
              onClick={() => navigate('/label')}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5" />
              {t('backToList')}
            </button>
            <h2 className="text-3xl font-bold text-gray-900">{t('labelDetails')}</h2>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={generatePDF}
              disabled={generating}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
            >
              <Download className="w-5 h-5" />
              {generating ? t('generatingLabel') : t('downloadLabel')}
            </button>
            <button
              onClick={() => navigate('/')}
              className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              <RotateCcw className="w-5 h-5" />
              {t('newRepair')}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8">
          {/* Label Preview */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">{t('labelPreview')}</h3>
            <LabelPreview repair={repair} />
          </div>

          {/* Repair Details */}
          <div className="space-y-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('repairDetails')}</h3>
              
              <dl className="space-y-4">
                <div>
                  <dt className="text-sm font-medium text-gray-500">{t('repairID')}</dt>
                  <dd className="mt-1 text-sm text-gray-900 font-mono">{repair.repair_id}</dd>
                </div>
                
                <div>
                  <dt className="text-sm font-medium text-gray-500">{t('customer')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <div>{repair.customer.name}</div>
                    <div className="text-gray-500">{repair.customer.phone}</div>
                  </dd>
                </div>

                <div>
                  <dt className="text-sm font-medium text-gray-500">{t('device')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {repair.device_type}
                    {repair.device_model && ` - ${repair.device_model}`}
                  </dd>
                </div>

                <div>
                  <dt className="text-sm font-medium text-gray-500">{t('issue')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">{repair.issue_description}</dd>
                </div>

                <div>
                  <dt className="text-sm font-medium text-gray-500">{t('status')}</dt>
                  <dd className="mt-1">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${
                        repair.status === 'completed'
                          ? 'bg-green-100 text-green-800'
                          : repair.status === 'diagnosed'
                          ? 'bg-blue-100 text-blue-800'
                          : repair.status === 'cancelled'
                          ? 'bg-red-100 text-red-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {t(repair.status)}
                    </span>
                  </dd>
                </div>

                <div>
                  <dt className="text-sm font-medium text-gray-500">{t('createdAt')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {new Date(repair.created_at).toLocaleString()}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('labelGeneration')}</h2>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      {/* Search Section */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <div className="flex gap-4 mb-4">
          <button
            onClick={() => setSearchType('customer')}
            className={`px-4 py-2 rounded-md ${
              searchType === 'customer'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {t('searchByCustomer')}
          </button>
          <button
            onClick={() => setSearchType('repair')}
            className={`px-4 py-2 rounded-md ${
              searchType === 'repair'
                ? 'bg-blue-100 text-blue-700'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {t('searchByRepairID')}
          </button>
        </div>

        <div className="relative">
          <div className="flex items-center gap-2">
            <Search className="w-5 h-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={searchType === 'customer' ? t('searchCustomerName') : t('searchRepairID')}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Latest Repairs */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">
          {searchQuery ? t('searchResults') : t('latestRepairs')}
        </h3>
        
        <div className="space-y-4">
          {latestRepairs.length > 0 ? (
            latestRepairs.map((repair) => (
              <button
                key={repair.id}
                onClick={() => navigate(`/label/${repair.repair_id}`)}
                className="w-full p-4 border rounded-lg hover:border-blue-500 transition-colors text-left"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="font-medium">{repair.customer.name}</div>
                    <div className="text-sm text-gray-500 mt-1">
                      {repair.device_type}
                      {repair.device_model && ` - ${repair.device_model}`}
                    </div>
                  </div>
                  <div className="text-sm text-gray-500">{repair.repair_id}</div>
                </div>
              </button>
            ))
          ) : (
            <div className="text-center text-gray-500 py-8">
              {searchQuery ? t('noRepairsFound') : t('noRecentRepairs')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabelGeneration;