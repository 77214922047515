import React from 'react';
import { NavLink } from 'react-router-dom';
import { Wrench, Tag, Clipboard, CreditCard, CheckCircle, History, DollarSign } from 'lucide-react';
import { t } from '../lib/translations';
import LanguageToggle from './LanguageToggle';
import { useAuth } from '../lib/auth';

const Sidebar = () => {
  const { user } = useAuth();
  
  const navItems = [
    { to: '/', icon: <Wrench className="w-5 h-5" />, label: t('customerIntake') },
    { to: '/history', icon: <History className="w-5 h-5" />, label: t('customerHistory') },
    { to: '/label', icon: <Tag className="w-5 h-5" />, label: t('labelGeneration') },
    { to: '/diagnostic', icon: <Clipboard className="w-5 h-5" />, label: t('diagnosticEntry') },
    { to: '/accounting', icon: <CreditCard className="w-5 h-5" />, label: t('accounting') },
    { to: '/completion', icon: <CheckCircle className="w-5 h-5" />, label: t('completion') },
    { to: '/daily-cash', icon: <DollarSign className="w-5 h-5" />, label: t('dailyCashRegister') },
  ];

  return (
    <aside className="w-64 bg-gray-900 text-white">
      <div className="p-6">
        <h1 className="text-2xl font-bold">pMaster</h1>
        <p className="text-gray-400 text-sm">PC Repair Management</p>
      </div>
      <nav className="mt-6">
        {navItems.map((item) => (
          <NavLink
            key={item.to}
            to={item.to}
            end
            className={({ isActive }) =>
              `flex items-center px-6 py-3 text-gray-300 hover:bg-gray-800 transition-colors ${
                isActive ? 'bg-blue-900 text-white' : ''
              }`
            }
          >
            {item.icon}
            <span className="ml-3">{item.label}</span>
          </NavLink>
        ))}
      </nav>
      <div className="mt-auto p-6">
        <LanguageToggle />
      </div>
    </aside>
  );
};

export default Sidebar;