import React from 'react';
import { t } from '../../lib/translations';

export default function Stats() {
  return (
    <div className="bg-blue-600 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold text-white mb-2">50%</div>
            <div className="text-blue-100">{t('lessAdminWork')}</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-white mb-2">100%</div>
            <div className="text-blue-100">{t('digitalTracking')}</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-white mb-2">24/7</div>
            <div className="text-blue-100">{t('statusTracking')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}