import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Laptop, 
  Printer, 
  Monitor, 
  Settings, 
  AlertCircle, 
  Search, 
  User, 
  Phone, 
  Camera, 
  X 
} from 'lucide-react';
import { useRepairOps } from '../lib/hooks/useRepairOps';
import { generateRepairId } from '../lib/utils';
import { t } from '../lib/translations';
import type { Database } from '../lib/database.types';

type Customer = Database['public']['Tables']['customers']['Row'];
type CustomerFormData = Database['public']['Tables']['customers']['Insert'];
type RepairFormData = Omit<Database['public']['Tables']['repairs']['Insert'], 'customer_id'>;

type FormErrors = {
  name?: string;
  phone?: string;
  device_type?: string;
  device_model?: string;
  issue_description?: string;
};

const deviceTypes = [
  { id: 'laptop', icon: <Laptop className="w-6 h-6" />, label: t('laptop') },
  { id: 'printer', icon: <Printer className="w-6 h-6" />, label: t('printer') },
  { id: 'monitor', icon: <Monitor className="w-6 h-6" />, label: t('monitor') },
  { id: 'other', icon: <Settings className="w-6 h-6" />, label: t('other') },
];

const MAX_PHOTOS = 4;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const CustomerIntake = () => {
  const navigate = useNavigate();
  const { createCustomerWithRepair, createRepairForExistingCustomer, searchCustomers, loading, error } = useRepairOps();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [searchResults, setSearchResults] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  
  const [customerData, setCustomerData] = useState<CustomerFormData>({
    name: '',
    phone: '',
  });
  
  const [repairData, setRepairData] = useState<RepairFormData>({
    repair_id: generateRepairId(),
    device_type: '',
    device_model: '',
    issue_description: '',
  });

  const [photos, setPhotos] = useState<File[]>([]);
  const [photoErrors, setPhotoErrors] = useState<string[]>([]);
  const [photoUrls, setPhotoUrls] = useState<string[]>([]);

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const newErrors: string[] = [];
    const validFiles: File[] = [];
    const urls: string[] = [];

    files.forEach(file => {
      if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
        newErrors.push(t('unsupportedImageType', { name: file.name }));
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        newErrors.push(t('fileTooLarge', { name: file.name }));
        return;
      }

      if (photos.length + validFiles.length >= MAX_PHOTOS) {
        newErrors.push(t('maxPhotosReached'));
        return;
      }

      validFiles.push(file);
      urls.push(URL.createObjectURL(file));
    });

    if (validFiles.length > 0) {
      setPhotos(prev => [...prev, ...validFiles]);
      setPhotoUrls(prev => [...prev, ...urls]);
    }

    if (newErrors.length > 0) {
      setPhotoErrors(newErrors);
    }
  };

  const removePhoto = (index: number) => {
    setPhotos(prev => prev.filter((_, i) => i !== index));
    URL.revokeObjectURL(photoUrls[index]);
    setPhotoUrls(prev => prev.filter((_, i) => i !== index));
    setPhotoErrors([]);
  };

  const handleSearch = async (field: 'name' | 'phone', value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setCustomerData(prev => ({ ...prev, [field]: value }));
    setFormErrors(prev => ({ ...prev, [field]: undefined }));

    if (value.length < 2) {
      setSearchResults([]);
      return;
    }

    const timeout = setTimeout(async () => {
      const results = await searchCustomers(value);
      if (results) {
        setSearchResults(results);
      }
    }, 300);

    setSearchTimeout(timeout);
  };

  const selectCustomer = (customer: Customer) => {
    setSelectedCustomer(customer);
    setCustomerData({
      name: customer.name,
      phone: customer.phone,
    });
    setSearchResults([]);
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    let isValid = true;

    if (!customerData.name.trim()) {
      errors.name = t('nameRequired');
      isValid = false;
    }

    if (!customerData.phone.trim()) {
      errors.phone = t('phoneRequired');
      isValid = false;
    } else if (!/^\+?[\d\s-()]+$/.test(customerData.phone)) {
      errors.phone = t('invalidPhone');
      isValid = false;
    }

    if (!repairData.device_type) {
      errors.device_type = t('deviceTypeRequired');
      isValid = false;
    }

    if (!repairData.device_model?.trim()) {
      errors.device_model = t('deviceModelRequired');
      isValid = false;
    }

    if (!repairData.issue_description.trim()) {
      errors.issue_description = t('issueDescriptionRequired');
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    let repair;
    if (selectedCustomer) {
      repair = await createRepairForExistingCustomer(selectedCustomer.id, repairData);
    } else {
      repair = await createCustomerWithRepair(customerData, repairData);
    }
    
    if (repair) {
      navigate(`/label/${repair.repair_id}`);
    }
  };

  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      photoUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [searchTimeout, photoUrls]);

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">{t('customerIntake')}</h2>
      
      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
          <p className="text-red-700">{error}</p>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-800 mb-6">{t('customerInformation')}</h3>
          <div className="grid grid-cols-2 gap-6">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('customerName')}
              </label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  value={customerData.name}
                  onChange={(e) => handleSearch('name', e.target.value)}
                  className={`w-full pl-10 pr-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                    formErrors.name ? 'border-red-500' : 'border-gray-300'
                  }`}
                  placeholder={t('searchOrEnterNewName')}
                />
              </div>
              {formErrors.name && (
                <p className="mt-1 text-sm text-red-600">{formErrors.name}</p>
              )}
              {searchResults.length > 0 && customerData.name.length >= 2 && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
                  {searchResults.map((customer) => (
                    <button
                      key={customer.id}
                      type="button"
                      onClick={() => selectCustomer(customer)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center gap-2"
                    >
                      <User className="w-4 h-4 text-gray-400" />
                      <div>
                        <div className="font-medium">{customer.name}</div>
                        <div className="text-sm text-gray-500">{customer.phone}</div>
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {t('phoneNumber')}
              </label>
              <div className="relative">
                <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="tel"
                  value={customerData.phone}
                  onChange={(e) => handleSearch('phone', e.target.value)}
                  className={`w-full pl-10 pr-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                    formErrors.phone ? 'border-red-500' : 'border-gray-300'
                  }`}
                  placeholder={t('searchOrEnterNewNumber')}
                />
              </div>
              {formErrors.phone && (
                <p className="mt-1 text-sm text-red-600">{formErrors.phone}</p>
              )}
              {searchResults.length > 0 && customerData.phone.length >= 2 && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
                  {searchResults.map((customer) => (
                    <button
                      key={customer.id}
                      type="button"
                      onClick={() => selectCustomer(customer)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center gap-2"
                    >
                      <Phone className="w-4 h-4 text-gray-400" />
                      <div>
                        <div className="font-medium">{customer.name}</div>
                        <div className="text-sm text-gray-500">{customer.phone}</div>
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-800 mb-6">{t('deviceInformation')}</h3>
          
          <div className="mb-6">
            <div className="grid grid-cols-4 gap-4">
              {deviceTypes.map((device) => (
                <button
                  key={device.id}
                  type="button"
                  onClick={() => {
                    setRepairData(prev => ({ ...prev, device_type: device.id }));
                    setFormErrors(prev => ({ ...prev, device_type: undefined }));
                  }}
                  className={`p-4 border rounded-lg flex flex-col items-center transition-colors
                    ${
                      repairData.device_type === device.id
                        ? 'border-blue-500 bg-blue-50 text-blue-700'
                        : formErrors.device_type
                        ? 'border-red-500'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                >
                  {device.icon}
                  <span className="mt-2 text-sm">{device.label}</span>
                </button>
              ))}
            </div>
            {formErrors.device_type && (
              <p className="mt-2 text-sm text-red-600">{formErrors.device_type}</p>
            )}
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('deviceModel')}
            </label>
            <input
              type="text"
              value={repairData.device_model || ''}
              onChange={(e) => {
                setRepairData(prev => ({ ...prev, device_model: e.target.value }));
                setFormErrors(prev => ({ ...prev, device_model: undefined }));
              }}
              placeholder="e.g., ThinkPad X1 Carbon, HP LaserJet Pro M404n"
              className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                formErrors.device_model ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {formErrors.device_model && (
              <p className="mt-1 text-sm text-red-600">{formErrors.device_model}</p>
            )}
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('devicePhotos')}
            </label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {photos.length < MAX_PHOTOS && (
                <div className="relative">
                  <input
                    type="file"
                    accept="image/jpeg,image/png,image/webp"
                    multiple
                    onChange={handlePhotoUpload}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    aria-label={t('addPhoto')}
                  />
                  <div className="aspect-square border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center hover:border-gray-400 transition-colors">
                    <Camera className="w-8 h-8 text-gray-400 mb-2" />
                    <span className="text-sm text-gray-500">{t('addPhoto')}</span>
                  </div>
                </div>
              )}

              {photoUrls.map((url, index) => (
                <div key={url} className="relative aspect-square">
                  <img
                    src={url}
                    alt={t('devicePhotoNum', { num: index + 1 })}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => removePhoto(index)}
                    className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>

            {photoErrors.length > 0 && (
              <div className="mt-2">
                {photoErrors.map((error, index) => (
                  <p key={index} className="text-sm text-red-600">{error}</p>
                ))}
              </div>
            )}
            <p className="mt-2 text-sm text-gray-500">
              {t('photoUploadInfo')}
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('issueDescription')}
            </label>
            <textarea
              value={repairData.issue_description}
              onChange={(e) => {
                setRepairData(prev => ({ ...prev, issue_description: e.target.value }));
                setFormErrors(prev => ({ ...prev, issue_description: undefined }));
              }}
              className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32 ${
                formErrors.issue_description ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {formErrors.issue_description && (
              <p className="mt-1 text-sm text-red-600">{formErrors.issue_description}</p>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors
              ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {loading ? t('submitting') : t('submitAndGenerateLabel')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomerIntake;