import { createClient } from '@supabase/supabase-js';
import { Database } from './database.types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.warn('Missing Supabase environment variables. Please connect your Supabase project first.');
}

export const supabase = createClient<Database>(
  supabaseUrl || 'https://placeholder.supabase.co',
  supabaseAnonKey || 'placeholder'
);

// Helper function to handle Supabase errors gracefully
export const handleSupabaseError = (error: unknown) => {
  if (error instanceof Error) {
    // Network errors
    if (error.message === 'Failed to fetch') {
      return 'Unable to connect to the database. Please check your connection.';
    }
    return error.message;
  }
  return 'An unexpected error occurred';
};